import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { ChevronsRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'

import { Card, Props as CardProps } from './Card'

export interface Props {
  description?: string
  cards: CardProps[]
  title?: string
}

export const Areas = memo(function Areas({ description, cards, title }: Props) {
  if (cards.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: cards.length > 3 ? true : false,
    slides: {
      perView: 3,
      spacing: 77,
    },
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    breakpoints: {
      '(max-width: 1439px)': {
        slides: {
          perView: 3,
          spacing: 50,
        },
      },
      '(max-width: 1023px)': {
        slides: {
          perView: 2.3,
          spacing: 30,
        },
      },
      '(max-width: 767px)': {
        slides: {
          perView: 1.01,
          spacing: 16,
        },
      },
    },
  })

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}

      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}

      <Wrapper>
        <Slider ref={sliderRef}>
          {cards.map((item, index) => (
            <Card className="keen-slider__slide" key={index} {...item} />
          ))}
        </Slider>

        {cards.length > 3 ? (
          <Arrows>
            <Arrow dial={5} onClick={() => instanceRef.current?.prev()}>
              <ChevronsRight />
            </Arrow>
            <Arrow dial={5} onClick={() => instanceRef.current?.next()}>
              <ChevronsRight />
            </Arrow>
          </Arrows>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin-top: 12.5rem;
  text-align: center;

  @media (max-width: 1023px) {
    margin-top: 7.5rem;
  }

  @media (max-width: 767px) {
    margin-top: 5.625rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryLight3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.6875rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 2.5rem;
    line-height: 2.9375rem;
    padding: 0 1.875rem;
  }
`

const Description = styled.div`
  max-width: 38.25rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
  margin: 1.875rem auto 0;

  @media (max-width: 1023px) {
    max-width: none;
    padding: 0 1.875rem;
  }
`

const Wrapper = styled.div`
  padding: 0 14.028vw 9rem;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 12.25rem;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  @media (max-width: 1199px) {
    padding-right: 5.556vw;
    padding-left: 5.556vw;
  }

  @media (max-width: 1023px) {
    padding: 0 1.875rem 5.625rem;
    &:before {
      top: 3.75rem;
    }
  }
`

const Slider = styled.div`
  display: flex;
  overflow: hidden;
  padding-top: 3.75rem;
  margin-top: 3.75rem;

  @media (max-width: 1023px) {
    overflow: visible;
    padding-top: 0;
  }
`

const Arrows = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`

const Arrow = styled(FlexBox)`
  width: 5.125rem;
  height: 5.125rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 9rem);
  left: 6.667vw;
  transform: translateY(-50%);
  transition: 0.2s ease-in-out;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.secondaryDark3};
    svg {
      stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    }
  }
  &:first-of-type {
    svg {
      transform: rotate(180deg);
    }
  }
  &:last-of-type {
    right: 6.667vw;
    left: auto;
  }

  svg {
    width: auto;
    height: 1.25rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.primaryLight3};
    transition: 0.2s ease-in-out;
  }
`
