import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  className?: string
  description?: string
  image?: ImageProps
  title?: string
}

export const Card = memo(function Card({
  className,
  description,
  image,
  title,
}: Props) {
  return (
    <Container className={className}>
      <Background>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}

        {title ? <Title>{title}</Title> : null}
      </Background>

      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  overflow: hidden;
  position: relative;
  &:nth-of-type(even) {
    margin-top: -3.75rem;
  }

  @media (max-width: 1023px) {
    &:nth-of-type(even) {
      margin-top: 0;
    }
  }
`

const Background = styled.div`
  background: ${({ theme }) => theme.colors.variants.secondaryDark3};
  padding-bottom: 133%;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 0.7)}
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.4375rem;
  position: absolute;
  bottom: 2.25rem;
  right: 3.125rem;
  left: 3.125rem;
  text-transform: uppercase;
  z-index: 2;

  @media (max-width: 1439px) {
    right: 1.25rem;
    left: 1.25rem;
  }

  @media (max-width: 1023px) {
    bottom: 1.875rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 1.875rem;

  @media (max-width: 1023px) {
    font-size: 0.9375rem;
    line-height: 1.625rem;
    margin-top: 1.5rem;
  }
`
